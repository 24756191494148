<template>
  <div
    v-if="asset"
    class="pb-2 mx-2 "
  >
    <div class="flex justify-between">
      <div class="text-sm truncate md:text-base lg:text-lg dark:text-gray-200">
        {{ asset.name }}
      </div>
      <div class="text-lg truncate">
        <v-popover v-if="user">
          <button
            class="btn btn-transparent dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-gray-200 focus:outline-none focus:text-gray-800"
            @click.prevent
          >
            <fa-icon icon="ellipsis" />
          </button>

          <template slot="popover">
            <ul class="text-sm leading-loose text-white">
              <li>
                <a
                  v-close-popover
                  href="#"
                  class="flex items-center justify-between hover:underline"
                  @click.prevent="onRename"
                >
                  Rename <fa-icon
                    class="ml-2"
                    icon="pen-to-square"
                  />
                </a>
              </li>

              <li>
                <a
                  v-close-popover
                  href="#"
                  class="flex items-center justify-between hover:underline"
                  @click.prevent="onDelete"
                >
                  Delete Asset <fa-icon
                    class="ml-2 text-red-500"
                    icon="trash-can"
                  />
                </a>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </div>

    <div class="flex justify-between">
      <div class="mb-2">
        <ContentStatusBadge :status="asset.status" />
        <ContentTypeBadge :type="asset.type" />
      </div>
    </div>

    <template v-if="showDescription">
      <div class="mt-2">
        <a
          v-if="showEdit"
          href="#"
          class="text-sm text-blue-500 border-b border-blue-500 border-dotted hover:text-blue-600 hover:border-solid"
          @click.prevent="onDescriptionModal"
        >Modify Description <fa-icon
          icon="pencil"
          class="ml-1"
        /></a>
        <div
          v-if="showBody"
          class="w-full mt-2 text-sm text-gray-800 rich-text dark:text-gray-300"
          v-html="asset.description"
        />
      </div>
    </template>

    <template v-if="false && hasData">
      <div class="pt-2 mt-2 text-sm border-t border-gray-200">
        <h3 class="text-base text-gray-700">
          Item Data
        </h3>
        <div class="p-2 font-mono text-xs text-gray-800 bg-gray-200">
          {{ asset.data }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
const ContentStatusBadge = () => import('@components/badges/ContentStatusBadge')
const ContentTypeBadge = () => import('@components/badges/ContentTypeBadge')

const PrevizRenameModal = () => import('@modals/PrevizRename')
const AssetDeleteModal = () => import('@modals/AssetDelete')
const AssetDescriptionModal = () => import('@modals/AssetDescription')

export default {
  name: 'ViewOverviewContainer',

  components: {
    ContentStatusBadge,
    ContentTypeBadge
  },

  props: {
    asset: {
      required: true
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    hasData () {
      return this.asset.data !== null
    },

    showEdit () {
      return this.user !== null
    },

    showBody () {
      return this.asset.description !== undefined && this.asset.description !== null
    },

    project () {
      return this.$store.getters['project/project']
    },

    user () {
      return this.$store.getters.user
    }
  },

  methods: {

    onDescriptionModal () {
      this.$bus.$emit('overlay:modal', { component: AssetDescriptionModal, size: 'md', props: { asset: this.asset } })
    },

    onDelete () {
      this.$modal.show(
        AssetDeleteModal, {
          assets: [this.asset],
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },
    onRename () {
      this.$modal.show(
        PrevizRenameModal, {
          asset: this.asset,
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    }

  }
}
</script>
